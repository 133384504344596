import { createRouter, createWebHistory, type LocationQuery, type RouteLocationNormalized } from "vue-router"
import { registerGuard } from "./MsalGuard"
import {
  isBackOfficeUser,
  isInvestor,
  isFundManagerAdmin,
  isFundReader,
  isInvestmentReader,
  isFundTransactionReader,
  isInvestmentTransactionReader,
  isFairValuesReader,
  isFairValuesAdmin,
  isFundBankAccountReader,
  isPortfolioInvestmentBankAccountReader,
  isGlobalAdmin,
  isReportingPeriodAdmin,
  isReportingPeriodReader,
  isExpenseReader,
  isFundPerformanceAdmin,
  isInvestorReportAdmin,
  isContactReader,
  isContactAdmin,
  isLimitedPartnerReader,
  isOnboardingReader,
  isOnboardingEditor,
  isInvoiceReader,
  isPortalReader,
  isCapitalCallReader,
  isCapitalCallEditor,
  isClosingReader,
  isClosingEditor,
  isTransferReader,
  isTransferEditor,
  isInvestmentCompanyAdmin,
  isInvestmentCompanyReader,
} from "@/modules/security/PoliciesValidator"

import { i18n } from "@/modules/i18n/i18nConfig"
import { getUsername } from "@/modules/security/AccountInfoHelper"
const t = i18n.global.t

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/HomeView.vue"),
      meta: { requiresAuth: true },
      beforeEnter() {
        return isBackOfficeUser() || isInvestor() || isGlobalAdmin() || "/access-denied"
      },
    },
    {
      path: "/funds",
      redirect: { name: "Funds Overview" },
      meta: { requiresAuth: true },
      children: [
        {
          path: "overview",
          name: "Funds Overview",
          component: () => import("@/views/backoffice/funds/_overview.vue"),
          beforeEnter() {
            return isFundReader() || "/access-denied"
          },
        },
        {
          path: ":fundId(\\d+)",
          redirect: { name: "Fund Performance" },
          name: "Fund",
          children: [
            {
              path: "performance",
              name: "Fund Performance",
              component: () => import("@/views/backoffice/funds/fund/_performance.vue"),
              beforeEnter() {
                return isFundPerformanceAdmin() || "/access-denied"
              },
            },
            {
              path: "details",
              name: "Fund Details",
              component: () => import("@/views/backoffice/funds/fund/_details.vue"),
              beforeEnter() {
                return isFundReader() || "/access-denied"
              },
            },
            {
              path: "investments",
              redirect: { name: "Fund Investments Overview" },
              children: [
                {
                  path: "overview",
                  name: "Fund Investments Overview",
                  component: () => import("@/views/backoffice/funds/fund/investments/_overview.vue"),
                  beforeEnter() {
                    return isInvestmentReader() || "/access-denied"
                  },
                },
                {
                  path: "add",
                  name: "Fund Investment Add",
                  component: () => import("@/views/backoffice/funds/fund/investments/_add.vue"),
                  beforeEnter() {
                    return isInvestmentReader() || "/access-denied"
                  },
                },
                {
                  path: ":investmentId(\\d+)",
                  redirect: { name: "Fund Investment Details" },
                  name: "Fund Investment",
                  component: () => import("@/views/backoffice/funds/fund/investments/investment/_index.vue"),
                  children: [
                    {
                      path: "details",
                      name: "Fund Investment Details",
                      component: () => import("@/views/backoffice/funds/fund/investments/investment/_details.vue"),
                      beforeEnter() {
                        return isInvestmentReader() || "/access-denied"
                      },
                    },
                    {
                      path: "companies",
                      name: "Portfolio Company overview",
                      children: [
                        {
                          path: "add",
                          name: "Portfolio Company Add",
                          component: () => import("@/views/backoffice/funds/fund/investments/_addCompany.vue"),
                          beforeEnter() {
                            return isInvestmentCompanyAdmin() || "/access-denied"
                          },
                        },
                        {
                          path: ":companyId(\\d+)",
                          redirect: { name: "Portfolio Company Details" },
                          beforeEnter() {
                            return isInvestmentCompanyReader() || "/access-denied"
                          },
                          children: [
                            {
                              path: "details",
                              name: "Portfolio Company Details",
                              component: () => import("@/views/backoffice/funds/fund/investments/investment/_company.vue"),
                              beforeEnter() {
                                return isInvestmentCompanyReader() || "/access-denied"
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: "transactions",
                      name: "Fund Investment Transactions",
                      redirect: { name: "Investment Transactions Overview" },
                      children: [
                        {
                          path: "overview",
                          name: "Investment Transactions Overview",
                          component: () => import("@/views/backoffice/transactions/_overview.vue"),
                          beforeEnter() {
                            return isInvestmentTransactionReader() || "/access-denied"
                          },
                        },
                        {
                          path: "add",
                          name: "Investment Transaction Add",
                          component: () => import("@/views/backoffice/transactions/_add.vue"),
                          beforeEnter() {
                            return isInvestmentTransactionReader() || "/access-denied"
                          },
                        },
                        {
                          path: ":transactionId(\\d+)",
                          redirect: { name: "Investment Transaction Details" },
                          name: "Investment Transaction",
                          children: [
                            {
                              path: "details",
                              name: "Investment Transaction Details",
                              component: () => import("@/views/backoffice/transactions/transaction/_details.vue"),
                              beforeEnter() {
                                return isInvestmentTransactionReader() || "/access-denied"
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: "bank-accounts",
                      name: "Fund Investment Bank Accounts",
                      redirect: { name: "Investment Bank Accounts Overview" },
                      children: [
                        {
                          path: "overview",
                          name: "Investment Bank Accounts Overview",
                          component: () => import("@/views/backoffice/bank-accounts/_overview.vue"),
                          beforeEnter() {
                            return isPortfolioInvestmentBankAccountReader() || "/access-denied"
                          },
                        },
                        {
                          path: ":investmentBankAccountId(\\d+)",
                          redirect: { name: "Investment Bank Account Details" },
                          name: "Investment Bank Account",
                          children: [
                            {
                              path: "details",
                              name: "Investment Bank Account Details",
                              component: () => import("@/views/backoffice/bank-accounts/bank-account/_details.vue"),
                              beforeEnter() {
                                return isPortfolioInvestmentBankAccountReader() || "/access-denied"
                              },
                            },
                          ],
                        },
                      ],
                    },
                    {
                      path: "fair-values",
                      name: "Fund Investment Fair Values",
                      redirect: { name: "Fair Values Overview" },
                      children: [
                        {
                          path: "overview",
                          name: "Fair Values Overview",
                          component: () => import("@/views/backoffice/funds/fund/investments/investment/fair-values/_overview.vue"),
                          beforeEnter() {
                            return isFairValuesReader() || "/access-denied"
                          },
                        },
                        {
                          path: "add",
                          name: "Fair Value Add",
                          component: () => import("@/views/backoffice/funds/fund/investments/investment/fair-values/_add.vue"),
                          beforeEnter() {
                            return isFairValuesAdmin() || "/access-denied"
                          },
                        },
                        {
                          path: ":fairValueId(\\d+)",
                          redirect: { name: "Fair Value Details" },
                          name: "Fair Value",
                          children: [
                            {
                              path: "details",
                              name: "Fair Value Details",
                              component: () => import("@/views/backoffice/funds/fund/investments/investment/fair-values/fair-value/_details.vue"),
                              beforeEnter() {
                                return isFairValuesReader() || "/access-denied"
                              },
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "transactions",
              redirect: { name: "Fund Transactions Overview" },
              component: () => import("@/views/backoffice/transactions/_index.vue"),
              children: [
                {
                  path: "overview",
                  name: "Fund Transactions Overview",
                  component: () => import("@/views/backoffice/transactions/_overview.vue"),
                  beforeEnter() {
                    return isFundTransactionReader() || "/access-denied"
                  },
                },
                {
                  path: "add",
                  name: "Fund Transaction Add",
                  component: () => import("@/views/backoffice/transactions/_add.vue"),
                  beforeEnter() {
                    return isFundTransactionReader() || "/access-denied"
                  },
                },
                {
                  path: ":transactionId(\\d+)",
                  redirect: { name: "Fund Transaction Details" },
                  name: "Fund Transaction",
                  children: [
                    {
                      path: "details",
                      name: "Fund Transaction Details",
                      component: () => import("@/views/backoffice/transactions/transaction/_details.vue"),
                      beforeEnter() {
                        return isFundTransactionReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "bank-accounts",
              redirect: { name: "Fund Bank Accounts Overview" },
              component: () => import("@/views/backoffice/bank-accounts/_index.vue"),
              children: [
                {
                  path: "overview",
                  name: "Fund Bank Accounts Overview",
                  component: () => import("@/views/backoffice/bank-accounts/_overview.vue"),
                  beforeEnter() {
                    return isFundBankAccountReader() || "/access-denied"
                  },
                },
                {
                  path: ":fundBankAccountId(\\d+)",
                  redirect: { name: "Fund Bank Account Details" },
                  name: "Fund Bank Account",
                  children: [
                    {
                      path: "details",
                      name: "Fund Bank Account Details",
                      component: () => import("@/views/backoffice/bank-accounts/bank-account/_details.vue"),
                      beforeEnter() {
                        return isFundBankAccountReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "expenses",
              redirect: { name: "Fund Expenses Overview" },
              children: [
                {
                  path: "overview",
                  name: "Fund Expenses Overview",
                  component: () => import("@/views/backoffice/funds/fund/expenses/_overview.vue"),
                  beforeEnter() {
                    return isExpenseReader() || "/access-denied"
                  },
                },
              ],
            },
            {
              path: "accounting",
              redirect: { name: "Accounting Overview" },
              children: [
                {
                  path: "overview",
                  name: "Accounting Overview",
                  component: () => import("@/views/backoffice/funds/fund/accounting/_overview.vue"),
                  beforeEnter() {
                    return isExpenseReader() || "/access-denied"
                  },
                },
              ],
            },
            {
              path: "onboarding",
              redirect: { name: "Fund Onboarding Overview" },
              children: [
                {
                  path: "overview",
                  name: "Fund Onboarding Overview",
                  component: () => import("@/views/backoffice/funds/fund/onboarding/_overview.vue"),
                  beforeEnter() {
                    return isOnboardingReader() || "/access-denied"
                  },
                },
                {
                  path: "add",
                  name: "Fund Onboarding Add",
                  component: () => import("@/views/backoffice/funds/fund/onboarding/_add.vue"),
                  beforeEnter() {
                    return isOnboardingEditor() || "/access-denied"
                  },
                },
                {
                  path: ":onboardingId(\\d+)",
                  redirect: { name: "Fund Onboarding Details" },
                  name: "Fund Onboarding",
                  children: [
                    {
                      path: "details",
                      name: "Fund Onboarding Details",
                      component: () => import("@/views/backoffice/funds/fund/onboarding/onboarding/_details.vue"),
                      beforeEnter() {
                        return isOnboardingReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "limited-partners",
              redirect: { name: "Limited Partners Overview" },
              children: [
                {
                  path: "overview",
                  name: "Limited Partners Overview",
                  component: () => import("@/views/backoffice/limited-partners/_overview.vue"),
                  beforeEnter() {
                    return isLimitedPartnerReader() || "/access-denied"
                  },
                },
                {
                  path: ":limitedPartnersId(\\d+)",
                  redirect: { name: "Limited Partners Details" },
                  name: "Limited Partners",
                  children: [
                    {
                      path: "details",
                      name: "Limited Partners Details",
                      component: () => import("@/views/backoffice/limited-partners/limited-partner/_details.vue"),
                      beforeEnter() {
                        return isLimitedPartnerReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "investor-reports",
              redirect: { name: "Investor Reports" },
              children: [
                {
                  path: "overview",
                  name: "Investor Reports",
                  component: () => import("@/views/backoffice/funds/fund/investor-reports/_overview.vue"),
                  beforeEnter() {
                    return isInvestorReportAdmin() || "/access-denied"
                  },
                },
              ],
            },
            {
              path: "reporting-periods",
              redirect: { name: "Fund Reporting Periods Overview" },
              children: [
                {
                  path: "overview",
                  name: "Fund Reporting Periods Overview",
                  component: () => import("@/views/backoffice/funds/fund/reporting-periods/_overview.vue"),
                  beforeEnter() {
                    return isReportingPeriodReader() || "/access-denied"
                  },
                },
                {
                  path: "add",
                  name: "Fund Reporting Periods Add",
                  component: () => import("@/views/backoffice/funds/fund/reporting-periods/_add.vue"),
                  beforeEnter() {
                    return isReportingPeriodAdmin() || "/access-denied"
                  },
                },
                {
                  path: ":reportingPeriodId(\\d+)",
                  redirect: { name: "Fund Reporting Period Details" },
                  name: "Fund Reporting Period",
                  children: [
                    {
                      path: "details",
                      name: "Fund Reporting Period Details",
                      component: () => import("@/views/backoffice/funds/fund/reporting-periods/reporting-period/_details.vue"),
                      beforeEnter() {
                        return isReportingPeriodReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "capital-movements",
              redirect: { name: "Capital Call Overview" },
              children: [
                {
                  path: "overview",
                  name: "Capital Call Overview",
                  component: () => import("@/views/backoffice/capital-movements/_overview.vue"),
                  beforeEnter() {
                    return isCapitalCallReader() || "/access-denied"
                  },
                },
                {
                  path: "add",
                  name: "Capital Call Add",
                  component: () => import("@/views/backoffice/capital-movements/_add.vue"),
                  beforeEnter() {
                    return isCapitalCallEditor() || "/access-denied"
                  },
                },
                {
                  path: ":capitalCallId(\\d+)",
                  redirect: { name: "Capital Call Details" },
                  name: "Capital Call",
                  children: [
                    {
                      path: "details",
                      name: "Capital Call Details",
                      component: () => import("@/views/backoffice/capital-movements/capital-movement/_details.vue"),
                      beforeEnter() {
                        return isCapitalCallReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "closings",
              redirect: { name: "Closing Overview" },
              children: [
                {
                  path: "overview",
                  name: "Closing Overview",
                  component: () => import("@/views/backoffice/closings/_overview.vue"),
                  beforeEnter() {
                    return isClosingReader() || "/access-denied"
                  },
                },
                {
                  path: "add",
                  name: "Closing Add",
                  component: () => import("@/views/backoffice/closings/_add.vue"),
                  beforeEnter() {
                    return isClosingEditor() || "/access-denied"
                  },
                },
                {
                  path: ":closingId(\\d+)",
                  redirect: { name: "Closing Details" },
                  name: "Closing",
                  children: [
                    {
                      path: "details",
                      name: "Closing Details",
                      component: () => import("@/views/backoffice/closings/closing/_details.vue"),
                      beforeEnter() {
                        return isClosingReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
            {
              path: "transfers",
              redirect: { name: "Transfer Overview" },
              children: [
                {
                  path: "overview",
                  name: "Transfer Overview",
                  component: () => import("@/views/backoffice/funds/fund/transfers/_overview.vue"),
                  beforeEnter() {
                    return isTransferReader() || "/access-denied"
                  },
                },

                {
                  path: "add",
                  name: "Transfer Add",
                  component: () => import("@/views/backoffice/funds/fund/transfers/_add.vue"),
                  beforeEnter() {
                    return isTransferEditor() || "/access-denied"
                  },
                },

                {
                  path: ":transferId(\\d+)",
                  redirect: { name: "Transfer Details" },
                  name: "Transfer",
                  children: [
                    {
                      path: "details",
                      name: "Transfer Details",
                      component: () => import("@/views/backoffice/funds/fund/transfers/transfer/_details.vue"),
                      beforeEnter() {
                        return isTransferReader() || "/access-denied"
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/contacts",
      redirect: { name: "Contacts Overview" },
      meta: { requiresAuth: true },
      children: [
        {
          path: "overview",
          name: "Contacts Overview",
          component: () => import("@/views/backoffice/limited-partners/contacts/_overview.vue"),
          beforeEnter() {
            return isContactReader() || "/access-denied"
          },
        },
        {
          path: "add",
          name: "Contact Add",
          component: () => import("@/views/backoffice/limited-partners/contacts/_add.vue"),
          beforeEnter() {
            return isContactAdmin() || "/access-denied"
          },
        },
        {
          path: ":contactId(\\d+)",
          redirect: { name: "Contact Details" },
          children: [
            {
              path: "details",
              name: "Contact Details",
              component: () => import("@/views/backoffice/limited-partners/contacts/contact/_details.vue"),
              beforeEnter() {
                return isContactReader() || "/access-denied"
              },
            },
          ],
        },
      ],
    },

    {
      path: "/invoices",
      redirect: { name: "Invoices Overview" },
      meta: { requiresAuth: true },
      children: [
        {
          path: "overview",
          name: "Invoices Overview",
          component: () => import("@/views/backoffice/invoices/_overview.vue"),
          beforeEnter() {
            return isInvoiceReader() || "/access-denied"
          },
        },
      ],
    },

    {
      path: "/reference",
      redirect: { name: "Fund Managers Overview" },
      component: () => import("@/views/backoffice/reference-data/_index.vue"),
      meta: { requiresAuth: true },
      children: [
        {
          path: "/fund-managers",
          redirect: { name: "Fund Managers Overview" },
          children: [
            {
              path: "overview/:showModal?",
              name: "Fund Managers Overview",
              component: () => import("@/views/backoffice/reference-data/fund-managers/_overview.vue"),
              beforeEnter() {
                return isBackOfficeUser() || "/access-denied"
              },
            },
            {
              path: "add",
              name: "Fund Manager Add",
              component: () => import("@/views/backoffice/reference-data/fund-managers/_add.vue"),
              beforeEnter() {
                return isFundManagerAdmin() || "/access-denied"
              },
            },
            {
              path: ":fundManagerId(\\d+)",
              redirect: { name: "Fund Manager Edit" },
              children: [
                {
                  path: "edit",
                  name: "Fund Manager Edit",
                  component: () => import("@/views/backoffice/reference-data/fund-managers/fund-manager/_edit.vue"),
                  beforeEnter() {
                    return isFundManagerAdmin() || "/access-denied"
                  },
                },
              ],
            },
          ],
        },
        {
          path: "/countries",
          redirect: { name: "Countries Overview" },
          children: [
            {
              path: "overview",
              name: "Countries Overview",
              component: () => import("@/views/backoffice/reference-data/countries/_overview.vue"),
            },
          ],
        },
        {
          path: "/currencies",
          redirect: { name: "Currencies Overview" },
          children: [
            {
              path: "overview",
              name: "Currencies Overview",
              component: () => import("@/views/backoffice/reference-data/currencies/_overview.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/portal",
      name: "Portal",
      redirect: { name: "Portal Funds" },
      meta: { requiresAuth: true },
      children: [
        {
          path: ":pTenantId(\\d+)",
          name: "Portal Tenant",
          redirect: { name: "Portal Funds" },
          beforeEnter() {
            return isPortalReader() || "/access-denied"
          },
          children: [
            {
              path: "funds",
              name: "Portal Funds",
              redirect: { name: "Portal Funds Overview" },
              children: [
                {
                  path: "overview",
                  name: "Portal Funds Overview",
                  component: () => import("@/views/portal/funds/_overview.vue"),
                  beforeEnter() {
                    return isInvestor() || "/access-denied"
                  },
                },
                {
                  path: ":pLpId(\\d+)",
                  name: "Portal Fund",
                  redirect: { name: "Portal Fund Details" },
                  children: [
                    {
                      path: "reports",
                      name: "Portal Fund Reports",
                      component: () => import("@/views/portal/funds/fund/_reports.vue"),
                    },
                    {
                      path: "details",
                      name: "Portal Fund Details",
                      component: () => import("@/views/portal/funds/fund/_details.vue"),
                    },
                    {
                      path: "investments",
                      name: "Portal Fund Investments",
                      redirect: { name: "Portal Fund Investments Overview" },
                      children: [
                        {
                          path: "overview",
                          name: "Portal Fund Investments Overview",
                          component: () => import("@/views/portal/funds/fund/_investments.vue"),
                        },
                        {
                          path: ":pInvestmentId(\\d+)",
                          name: "Portal Fund Investment",
                          component: () => import("@/views/portal/funds/fund/_investment.vue"),
                        },
                      ],
                    },
                    {
                      path: "capital-movements",
                      name: "Portal Fund Capital Calls",
                      redirect: { name: "Portal Fund Capital Calls Overview" },
                      children: [
                        {
                          path: "overview",
                          name: "Portal Fund Capital Calls Overview",
                          component: () => import("@/views/portal/funds/fund/_capitalCalls.vue"),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      path: "/access-denied",
      name: "access-denied",
      redirect: { name: "Error", query: { code: "403", text: t("general.accessDenied") } },
    },
    {
      path: "/not-found",
      name: "Not Found",
      component: () => import("@/views/NotFoundView.vue"),
    },
    {
      path: "/error",
      name: "Error",
      component: () => import("@/views/ErrorView.vue"),
    },
    {
      path: "/onboarding",
      name: "Onboarding",
      redirect: { name: "Onboarding Wizard New" },
      children: [
        {
          path: "new",
          name: "Onboarding Wizard New",
          component: () => import("@/views/onboarding/OnboardingView.vue"),
        },
        {
          path: ":owId(\\d+)",
          redirect: { name: "Onboarding Wizard" },
          meta: { requiresAuth: true },
          children: [
            {
              path: "details",
              name: "Onboarding Wizard",
              component: () => import("@/views/onboarding/OnboardingView.vue"),
            },
          ],
        },
      ],
    },
    {
      path: "/email-processing/:emailGuid([a-f0-9]{8}-[a-f0-9]{4}-[1-5][a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12})",
      name: "Email Processing",
      redirect: { name: "Error", query: { code: "404", text: t("error.404.text") } },
      children: [
        {
          path: "account-creating",
          name: "Account Creating",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_accountCreating.vue"),
          beforeEnter() {
            return isGlobalAdmin() || "/access-denied"
          },
        },
        {
          path: "account-created",
          name: "Account Created",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_accountCreated.vue"),
          beforeEnter(to: RouteLocationNormalized) {
            const query: LocationQuery = to.query
            const emailFromQuery: string | undefined = query.ea?.toString()
            if (emailFromQuery !== getUsername()) {
              return "/"
            }
            return true
          },
        },
        {
          path: "account-reactivated",
          name: "Account Reactivated",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_accountReactivated.vue"),
        },
        {
          path: "capital-call-published",
          name: "CapitalCall Published",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_capitalCallPublished.vue"),
          beforeEnter() {
            return isInvestor() || "/access-denied"
          },
        },
        {
          path: "reporting-period-published",
          name: "Reporting Period Published",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_reportingPeriodPublished.vue"),
          beforeEnter() {
            return isInvestor() || "/access-denied"
          },
        },
        {
          path: "start-onboarding",
          name: "Start onboarding",
          component: () => import("@/views/email-processing/_startOnboarding.vue"),
        },
        {
          path: "complete-onboarding",
          name: "Complete onboarding",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_completeOnboarding.vue"),
        },
        {
          path: "due-diligence-signed",
          name: "Due Diligence Signed",
          component: () => import("@/views/email-processing/_dueDiligenceSigned.vue"),
        },
        {
          path: "due-diligence-submitted",
          name: "Due Diligence Submitted",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_dueDiligenceSubmitted.vue"),
          beforeEnter() {
            return isOnboardingEditor() || "/access-denied"
          },
        },
        {
          path: "subscription-submitted",
          name: "Subscription Submitted",
          meta: { requiresAuth: true },
          component: () => import("@/views/email-processing/_subscriptionSubmitted.vue"),
          beforeEnter() {
            return isOnboardingEditor() || "/access-denied"
          },
        },
      ],
    },
    {
      path: "/:pathMatch(.*)*",
      name: "Error Match",
      // component: () => import("@/views/ErrorView.vue"),
      redirect: { name: "Error", query: { code: "404", text: t("error.404.text") } },
    },
  ],
})

registerGuard(router, "/access-denied")

export default router
